import {NgModule} from "@angular/core";
import {CommonModule, DatePipe} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
    NgbAccordionModule, NgbCollapse,
    NgbDatepickerModule, NgbDropdownModule, NgbNavModule, NgbPagination, NgbTooltipModule, NgbTypeaheadModule
} from "@ng-bootstrap/ng-bootstrap";
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
import { DelayedInputDirective } from './directives/delayed-input.directive';
import {NgSelectModule} from "@ng-select/ng-select";
import {A11yModule} from "@angular/cdk/a11y";
import { AccountModalComponent } from './components/modals/account-modal/account-modal.component';
import {NgApexchartsModule} from 'ng-apexcharts';

@NgModule({
    imports: [
        CommonModule,
        A11yModule,
        FormsModule,
        ReactiveFormsModule,
        NgbAccordionModule,
        NgbDropdownModule,
        NgbDatepickerModule,
        NgbNavModule,
        NgbCollapse,
        NgbTooltipModule,
        NgbPagination,
        NgxMaskDirective,
        NgxMaskPipe,
        NgSelectModule,
        NgApexchartsModule
    ],
    exports: [
        CommonModule,
        A11yModule,
        FormsModule,
        ReactiveFormsModule,
        NgbAccordionModule,
        NgbDropdownModule,
        NgbDatepickerModule,
        NgbTypeaheadModule,
        NgbNavModule,
        NgbCollapse,
        NgbTooltipModule,
        NgbPagination,
        NgxMaskDirective,
        NgxMaskPipe,
        DelayedInputDirective
    ],
    providers: [
        DatePipe,
        provideNgxMask()
    ],
    declarations: [
      DelayedInputDirective,
      AccountModalComponent,
    ]
})
export class SharedModule {
}
