import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {BaseService} from './base.service';
import {ToastService} from "./toast.service";

@Injectable({
    providedIn: 'root'
})
export abstract class ApiService extends BaseService {
    protected constructor(
        public override toastService: ToastService,
        protected httpClient: HttpClient,
    ) {
        super(toastService);
    }

    public list(route: string): Observable<any> {
        return this.httpClient
            .get(`${this.apiUrl}/${route}`)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public queryList(route: string, pageNumber: number = 1, pageSize: number = 50): Observable<any> {
        return this.httpClient
            .get(`${this.apiUrl}/${route}&page[number]=${pageNumber}&page[size]=${pageSize}`)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public get(route: string, options?: any): Observable<any> {
        return this.httpClient
            .get<any>(`${this.apiUrl}/${route}`, options)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public post(route: string, data: any): Observable<any> {
        return this.httpClient
            .post<any>(`${this.apiUrl}/${route}`, data)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public create(route: string, data: any): Observable<any> {
        return this.httpClient
            .post<any>(`${this.apiUrl}/${route}`, data)
            .pipe();
    }

    public update(route: string, id: string | number, data: any): Observable<any> {
        return this.httpClient
            .put<any>(`${this.apiUrl}/${route}/${id}`, data)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public delete(route: string, id: string | number): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/${route}/${id}`);
    }
}
