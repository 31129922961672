<div class="modal-header">
    <h5 class="modal-title fw-bold">Connect to {{ authService.tenant.integrations.account.type.title }}</h5>
</div>

<div class="modal-body">
    <ul class="list-group list-group-flush">
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <b>Status</b> <span>{{ authService.tenant.integrations.account.status ? 'Connected' : 'Unconnected' }}</span>
        </li>

        <li class="list-group-item d-flex justify-content-between align-items-center">
            <b>Last Connected At</b> <span>{{ authService.tenant.integrations.account.last_connected_at | date }}</span>
        </li>

        <li class="list-group-item d-flex justify-content-between align-items-center">
            <b>Connected By</b> <span>{{ authService.tenant.integrations.account.author.name }}</span>
        </li>
    </ul>
</div>

<div class="modal-footer justify-content-between">
    <a href="https://central.xero.com/s/article/Getting-started-with-Xero-Connected-Apps" target="_blank" class="btn btn-link">read more <i class="fa-solid fa-arrow-up-right-from-square"></i></a>

   <button class="btn btn-primary" (click)="connect(authService.tenant.integrations.account.type.id)"><i class="fa-solid fa-check"></i> Connect</button>
</div>
